import logo from './img/logo.png';
import { Link } from "react-router-dom";


function Footer() {
    return (
        <footer>
            <div className="footer-container" id='kontakt'>
                <div className="column1">
                    <img src={logo} className="logo-footer" alt="logo"/>
                </div>
                <div className="column2">
                    <ul>
                        <li><a href="/#functionalities" className='footer-link'>POZNAJ SYSTEM</a></li>
                        <li><a href="/#functionalities" className='footer-link'>FUNKCJONALNOŚCI</a></li>
                        <li><a href="/#dla-kogo" className='footer-link'>DLA KOGO?</a></li>
                        <li><a href="/privacy" className='footer-link'>POLITYKA PRYWATNOŚCI</a></li>
                    </ul>
                </div>
                <div className="column3">
                    <Link to="/cennik" className='footer-link'>CENNIK</Link>
                </div>
                <div className="column4">
                    <h5>KONTAKT</h5>
                    <p>kontakt@egodziny.pl</p>
                    <p>+48 515 800 125</p>
                </div>
            </div>

            <p className="copyright">2024  © eGodziny</p>
        </footer>
    )
}

export default Footer;